import logo from "./pema-logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="content-box">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="App-title">Site en cours de développement</h1>
        <p className="App-subtitle">
          Nous travaillons dur pour créer quelque chose d'incroyable. Revenez
          bientôt pour découvrir notre nouveau site !
        </p>
      </div>
    </div>
  );
}

export default App;
